* {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: sourceSansProFont;
  src: url('./../../public/fonts/SourceSans3-VariableFont_wght.ttf');
  format: ('ttf');
  font-display: swap;
}
